import { range } from 'ramda'
import { isNode } from 'beanie-engine-api-js'

import { toBNEAction } from '../utils'
import { _moveRangeAfter } from './moveRangeAfter'
import { isUpdatable } from '../checkParentshipChanges'

/**
 *
 */
export const _pasteNodesAfter = (objsToCopy, fromId, toId, afterId, updateIfExists) => api => {
  const objsToAdd = []
  const objsToUpdate = []

  let existingNodesAmount = 0

  objsToCopy.forEach(obj => {
    if (isUpdatable(api, obj)) {
      objsToUpdate.push(obj)
      if (isNode(obj.sys)) existingNodesAmount++
    } else {
      objsToAdd.push(obj)
    }
  })

  const previousChild = range(0, existingNodesAmount).reduce(acc => acc.get_child_node(), api.obj.get(afterId))

  objsToAdd.forEach(obj => {
    api.createObject(obj)
  })

  objsToUpdate.forEach(obj => {
    api.update(obj.id, obj.data)
  })

  if (fromId !== afterId) { // avoid head to parent to itself
    _moveRangeAfter(fromId, toId, afterId, true)(api)
  } else if (updateIfExists) {
    const to = api.obj.get(toId)
    if (previousChild && previousChild.get_id() !== to.get_id()) to.private_set_child(previousChild)
  }

}

export default toBNEAction(_pasteNodesAfter, 'Paste nodes')
