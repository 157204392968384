import { revisionId as revisionIdSelector } from 'selectors/project'

export const pasteNodesConfig = (getState, clipboardContent, keepSourceIds) => {
  const targetRevisionId = revisionIdSelector(getState())
  const sourceRevisionId = clipboardContent.manifest?.sourceRevision.id
  const pastingAtSameRevision = sourceRevisionId === targetRevisionId
  return {
    keepSourceIds: keepSourceIds || !pastingAtSameRevision
  }
}
