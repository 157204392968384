import { equals, identity } from 'ramda'
import { DropTarget } from 'react-dnd'
import { connect } from 'react-redux'
import { compose, defaultProps, withPropsOnChange, withState } from 'recompose'

import { DnDTypes } from 'dnd/model-dnd'

import asTransaction from 'actions/utils/asTransaction'
import computeHoverPosition from 'utils/dnd/computeHoverPosition'
import { isHoveringBottom, isHoveringLeft, isHoveringRight, isHoveringTop } from 'utils/dnd/position'
import nodeComputePosition from 'utils/dnd/position/compute/nodeComputePosition'

import dropAction from 'actions/nodes/dnd/drop'
import computeTargetOperationAction from 'actions/nodes/dnd/computeTargetOperation'
import whenRevisionModifiable from '../../../hocs/project/revision/whenRevisionModifiable'

export const dropActions = {
  // target
  // TODO: maybe inform the affected object' ids
  onDrop: asTransaction(dropAction, 'Drag & drop'),
  computeTargetOperation: computeTargetOperationAction
}

const targetCollect = (conn, monitor) => {
  const canDrop = monitor.isOver() && monitor.canDrop()
  return ({
    connectDropTarget: conn.dropTarget(),
    canDrop,
  })
}

export const _withNodeDropBehavior = [
  withState('dropOperation', 'setDropOperation'),

  DropTarget(
    [DnDTypes.Node],
    {
      /**
       * Computes which operation can be done if any for this dragged selection into this node + relationship + position
       */
      hover({ node, relationship, computeTargetOperation, dropOperation, setDropOperation }, monitor, component) {

        const handleNewPosition = hoverPosition => {
          const item = monitor.getItem()
          const nextOperation = computeTargetOperation({
            selections: item.selection,
            node,
            relationship,
            sourceRelationship: item.relationship,
            hoverPosition,
          })
          if (!equals(nextOperation, dropOperation)) { // we need this deep equal here
            setDropOperation(nextOperation)
          }
        }
        computeHoverPosition(node.id, dropOperation?.hoverPosition, handleNewPosition, monitor, component, nodeComputePosition)
      },

      canDrop: ({ dropOperation }) => !!dropOperation,

      drop({ onDrop, dropOperation }, monitor) {
        if (!dropOperation) { return }
        onDrop(monitor.getItem(), dropOperation)
      },

    },
    targetCollect,
  ),
  withPropsOnChange(
    ['canDrop', 'dropOperation'],
    props => {
      const { canDrop, dropOperation } = props
      const hoverPosition = dropOperation?.hoverPosition
      return canDrop && hoverPosition && ({
        droppingLeft: isHoveringLeft(hoverPosition),
        droppingRight: isHoveringRight(hoverPosition),
        droppingAbove: isHoveringTop(hoverPosition),
        droppingBelow: isHoveringBottom(hoverPosition),
      })
    }
  )
]

/**
 * As a target to drop nodes. Connected standalone usage
 */
export const withNodeDropBehavior = whenRevisionModifiable(
  compose(
    connect(null, dropActions),
    ..._withNodeDropBehavior
  ),
  // when disabled
  defaultProps({
    connectDropTarget: identity
  }),
)

export default withNodeDropBehavior
