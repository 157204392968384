import uuid from 'uuid/v4'
import { append, assoc, chain, defaultTo, dissocPath, flatten, identity, keys, lensProp, lensPath, over, pipe, propOr, reduce } from 'ramda'
import { model, ref, Sys } from 'beanie-engine-api-js'

const { types: { object: { Paths } } } = model

// don't make it point free, as it will always return same values as first usage
const cloneSource = o => assoc('id', uuid())(o)

const setIntoResultObj = aProp => (key, value) => over(lensProp(aProp), assoc(key, value))
const addOldToNewId = setIntoResultObj('oldToNewId')
const addToCopies = copyObj => setIntoResultObj('copies')(copyObj.id, copyObj)

export /* to test */ const contentToCopies = ({ keepSourceIds }, { content /* , manifest */ }) => {
  return pipe(
    ...flatten(
      keys(content)
        .map(sourceId => {
          const sourceObj = content[sourceId]
          let copyObj

          let steps = []
          if (keepSourceIds) {
            copyObj = sourceObj
          } else {
            copyObj = cloneSource(sourceObj)
            steps = append(addOldToNewId(sourceId, copyObj.id), steps)
          }

          steps = append(addToCopies(copyObj), steps)
          return steps
        })
    )
  )
}

// TODO: actor's dictionary could be a (target) local selector used also for actor name autocompletion
// check ActorNameSuggestionPlugin.js ; const makeActorSelector = () => createSelector(
// for reactive actor name alphabetically sorted (excluding DIR) selector reference
const buildActorsDictionary = idx => pipe(
  keys,
  reduce(
    (acc, key) => {
      const obj = idx[key]
      if (obj.sys !== Sys.actor) return acc
      acc[obj.data.actorName] = obj
      return acc
    },
    {}
  ),
)(idx)

export /* to test */ const contextToCopies = ({ context }, idx) => {
  let actorNamesDictionary
  const getActorByName = name => {
    if (!actorNamesDictionary) { actorNamesDictionary = buildActorsDictionary(idx) }
    return actorNamesDictionary[name]
  }

  const contextOperationsOnCopies =
    keys(context)
      .map(sourceId => {
        const existsOnTarget = !!idx[sourceId]
        const sourceObj = context[sourceId]

        if (existsOnTarget) return identity

        if (sourceObj.sys === Sys.actor) {
          // Special treatment for actors: search existent by actor name
          const actorWithSameName = getActorByName(sourceObj.data.actorName)

          if (actorWithSameName) return addOldToNewId(sourceObj.id, actorWithSameName.id)
        }
        // if the context object does not exist in the target then we add it as we will need it
        return addToCopies(sourceObj)
      })

  if (contextOperationsOnCopies.length === 0) return identity
  return pipe(...contextOperationsOnCopies)
}

export /* to test */ const replaceRefs = ({ referencesMap }) => ({ oldToNewId, copies }) => {
  const operationsOnCopies = pipe(
    keys,
    chain(oldId => {
      const transformReferences = (referencesMap[oldId] || []).map(({ from, path }) => {
        const newTo = defaultTo(oldId, oldToNewId[oldId])
        const newFrom = defaultTo(from, oldToNewId[from])
        // swaps reference to to match new id
        return over(lensPath([newFrom, ...path]), () => ref(newTo))
      })

      return transformReferences
    })
  )(oldToNewId)

  const newCopies = pipe(
    ...(operationsOnCopies.length > 0 ? operationsOnCopies : [identity])
  )(copies)
  return { copies: newCopies, oldToNewId }
}

export /* to test */ const addHeadAndTail = ({ headId, tailId }) => ({ copies, oldToNewId }) => {
  const newHeadId = propOr(headId, headId, oldToNewId)
  const newTailId = propOr(tailId, tailId, oldToNewId)
  return ({
    copies: pipe(
      dissocPath([newHeadId, ...Paths.node.parent]),
      dissocPath([newTailId, ...Paths.node.child]),
    )(copies),
    headId: newHeadId,
    tailId: newTailId,
  })
}

/**
 * pasteNodes
 *
 * It does not actually create objects on the revision. It returns copies so that you
 * can call to `api.createObjects(copies)`
 *
 * @param clipboard clipboardContent
 * @param objectsIndex
 * @param revisionId
 * @param {Object} config config object
 * @param {boolean} config.keepSourceIds - Overwrite revision objects and keep ids from clipboard
 *
 * @returns an object with
 * {
 *   copies: {
 *     [id] -> <obj>
 *   },
 *   headId: id,
 *   tailId: id
 * }
 *
 */
export default (clipboard, objectsIndex, revisionId, config) => {
  // TODO: Add Clipboard type checks
  return pipe(
    contentToCopies(config, clipboard),
    contextToCopies(clipboard, objectsIndex),
    replaceRefs(clipboard),
    addHeadAndTail(clipboard)
  )({})

}
