import moment from 'moment'
import { pipe } from 'ramda'

export const NEGATIVE_BUFFER_SECONDS = 100
export const timeToUi = t => t + NEGATIVE_BUFFER_SECONDS
export const uiToTime = t => t - NEGATIVE_BUFFER_SECONDS
export const showTime = pipe(
  uiToTime,
  (s = 0) => moment.duration(s * 1000).format('H:mm:ss.SSS', { trim: false })
)

export const getMinTime = tlData =>
  tlData.reduce((acc, cur) => {
    const actionMin = cur.actions.reduce((acc2, cur2) => {
      if (cur2.start < acc2) {
        return cur2.start
      }
      return acc2
    }, Infinity)

    if (actionMin < acc) {
      return actionMin
    }
    return acc
  }, Infinity)
