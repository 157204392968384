import React from 'react'
import { prop, values } from 'ramda'
import { isNotEmpty } from 'ramda-adjunct'
import { Modal, Icon } from 'antd'
import { ref, parseRef, isNode } from 'beanie-engine-api-js'

import { noop } from 'utils/functions'
import { isFirst } from 'utils/object'
import { capitalise } from 'utils/string'

import { objectById } from 'selectors/objects'

const NEW_ROOT = 'NEW_ROOT'
const NEW_LANE = 'NEW_LANE'

const equalRefs = (id1, id2) => ref(id1) === ref(id2)

export const isUpdatable = (api, obj) => api.obj.get(obj.id) && api.obj.get(obj.id).get_data

const calcNewParent = (objects, afterNode, newLane, object) => {
  if (newLane) return NEW_LANE

  const isFirstElem = isFirst(object, objects, prop('id'))

  if (!afterNode) { // pasting a lane or a chain
    return isFirstElem ? NEW_ROOT : object.data.parent
  }

  if (isFirstElem) {
    if (equalRefs(object.id, afterNode.id)) return afterNode.data.parent
    return afterNode.id
  }

  return object.data.parent
}

const calcNodesToReparent = ({ afterNode, newLane, clipboard, config, getState }) => {
  if (!config.keepSourceIds) return []
  const objects = values(clipboard.content)
  return objects
    .filter(obj => isNode(obj.sys))
    .reduce((acc, object) => {
      const targetObject = objectById(object.id)(getState())
      return targetObject ? [...acc, { object, targetObject }] : acc
    }, [])
    .map(({ object, targetObject }) => ({
      object,
      newParent: parseRef(calcNewParent(objects, afterNode, newLane, object)),
      oldParent: parseRef(targetObject.data.parent)
    }))
    .filter(({ newParent, oldParent }) => newParent !== oldParent)
}

const WarningReparentMessage = ({ reparents, newLane }) => {
  const reparentsMessages = reparents.map(
    ({ object, newParent, oldParent }) => {
      if (newParent === NEW_LANE) return `${capitalise(object.sys)} with id ${object.id}: has to be moved to new lane "${newLane}"`
      if (newParent === NEW_ROOT) return `${capitalise(object.sys)} with id ${object.id}: has to be moved to another chain`
      return `${capitalise(object.sys)} with id ${object.id}: from parent ${oldParent} to ${newParent}`
    }
  )

  return (
    <div>
      <p>Requires to make parentship changes.</p>
      <ul>
        {reparentsMessages.map(reparent => <li>{reparent}</li>)}
      </ul>
      <h4>Do you wish to paste using new ids for all?</h4>
    </div>
  )
}

export default (afterNode, clipboard, config, newLane, cb) => async (dispatch, getState) => {
  const onOk = async () => {
    config.keepSourceIds = false
    await cb()
  }

  const nodesToReparent = calcNodesToReparent({ afterNode, newLane, clipboard, config, getState })
  if (isNotEmpty(nodesToReparent)) {
    Modal.confirm({
      icon: <Icon type="warning" />,
      width: '80%',
      title: 'Cannot paste using same ids',
      content: <WarningReparentMessage reparents={nodesToReparent} newLane={newLane} />,
      onOk,
      onCancel: noop,
      okText: 'Paste with new ids',
      cancelText: 'Cancel'
    })
  } else {
    await cb()
  }

}
