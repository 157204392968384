import {
  always,
  prop,
  flip,
  mergeAll,
  chain,
  head,
  intersperse as ramdaIntersperse,
  map,
  toPairs,
  fromPairs,
  filter,
  split,
  pipe,
  T,
  tail,
  identity,
  assocPath,
  path,
  pathOr,
  keys,
  when,
  curryN
} from 'ramda'

export const propertyValues = obj => Object.keys(obj).map(k => obj[k])

export const properties = obj => keys(obj).map(k => ({ name: k, value: obj[k] }))

/* eslint no-shadow: 0 */
export const mapObject = (obj, mapper = p => p.value) => properties(obj).reduce((acc, prop) => {
  acc[prop.name] = mapper(prop)
  return acc
}, {})

const isPropertyInLens = (propName, lens = []) => isEmpty(lens) || head(lens) === propName
const doExtractLens = lens => props => {
  if (lens.length - 1 < 0) return props
  const pathToNavigate = ramdaIntersperse('children', lens.map(always(0))).concat(['children'])
  return pathOr([], pathToNavigate, props)
}
export const propertiesOf = (object, hidden = [], pathPrefix, lens = [], extractLens = false) =>
  pipe(
    properties,
    filter(({ name }) => !hidden.includes(name) && isPropertyInLens(name, lens)),
    map(prop => ({ ...prop, path: pathPrefix ? `${pathPrefix}.${prop.name}` : prop.name })),
    map(property => (isComplexObject(property.value) ?
      {
        ...property,
        children: propertiesOf(property.value, hidden, property.path, tail(lens))
      }
      : property
    )),
    when(() => !isEmpty(lens) && extractLens, doExtractLens(lens))
  )(object)


export const propFrom = flip(prop)

export const updatePath = (obj, propertyPath, value) => {
  const path = propertyPath.split('.')
  path.reduce((acc, prop, i) => {
    // last
    if (i === path.length - 1) {
      if (value === null || value === undefined) {
        delete acc[prop]
      } else {
        acc[prop] = value
      }
      return obj
    } else {
      if (!acc[prop]) acc[prop] = {}
      return acc[prop]
    }
  }, obj)
  return obj
}

const toArrayPath = path => (!isArray(path) ? path.split('.') : path)
export const toNestedPropertyValue = (path, value) => {
  const initial = {}
  const propPath = toArrayPath(path)
  propPath.reduce((acc, field, i) => {
    const sub = {}
    acc[field] = i === propPath.length - 1 ? value : sub
    return sub
  }, initial)
  return initial
}

export const onlyOneIsUndefined = (a, b) => (a && !b) || (b && !a)

export const isBasicType = obj => typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'boolean'
export const isComplexObject = o => o && typeof o === 'object' && !Array.isArray(o)

export const flattenObj = (obj, follow = v => typeof v === 'object') => {
  const go = o => chain(([key, value]) => (
    follow(value) ?
      map(([innerKey, innerValue]) => [`${key}.${innerKey}`, innerValue], go(value))
      : [[key, value]]
  ), toPairs(o))

  return fromPairs(go(obj))
}

export const allPathsOf = pipe(flattenObj, Object.keys, map(split('.')))

export const updateManyProps = (object, valueTransform = identity, { pathsFilter = T, pathTransformation = identity } = {}) =>
  allPathsOf(object)
    .filter(pathsFilter)
    .map(pathTransformation)
    .reduce((updatedObject, aPath) =>
      assocPath(
        aPath,
        valueTransform(path(aPath, object)),
        updatedObject
      ),
    object)

// array

export const intersperse = (array, separator) => {
  return array.length === 0 ?
    []
    : array.slice(1).reduce((acc, e, i) => {
      return acc.concat([typeof separator === 'function' ? separator(i) : separator, e])
    }, [array[0]])
}

export const curriedIntersperse = pipe(flip, curryN(2))(intersperse)

/* eslint prefer-spread: 0 */
export const flatten = arrays => [].concat.apply([], arrays)
export const isEmpty = array => !array || array.length === 0
export const first = array => array && array[0]
export const last = array => array && array[array.length - 1]

export const isFirst = (object, objects, by = identity) => by(first(objects)) === by(object)

export const unique = filter((item, pos, self) => self.indexOf(item) === pos)

export const isFunction = value => typeof value === 'function'

export const moveToIndex = (e, index, list) => moveArrayIndexTo(list.indexOf(e), index, list)

export const moveArrayIndexTo = curryN(3, (fromIndex, toIndex, list) => {
  const e = list[fromIndex]
  if (fromIndex > toIndex) {
    // move to left
    return list
      .slice(0, toIndex)
      .concat(e)
      .concat(list.slice(toIndex, fromIndex))
      .concat(list.slice(fromIndex + 1, list.length))
  } else {
    // move to right
    return list
      .slice(0, fromIndex)
      .concat(list.slice(fromIndex + 1, toIndex + 1))
      .concat(e)
      .concat(list.slice(toIndex + 1, list.length))
  }
})
export const moveToBefore = (e, before, list) => moveToIndex(e, list.indexOf(before), list)

export const arrayToObject = array => mergeAll(array.map(_ => ({ [_]: _ })))
export const toArray = o => (Array.isArray(o) ? o : [o])
export const isArray = o => o && Array.isArray(o)

export const removeDuplicates = arr => [...new Set(arr)]

export const EMPTY_ARRAY = []
export const EMPTY_OBJECT = Object.freeze({})

// urls / string

export const stripEndSlash = string => (string[string.length - 1] === '/' ?
  string.substr(0, string.length - 1)
  : string
)

export const isEmptyObject = obj => !obj || Object.keys(obj).length === 0
export const emptyObjectToArray = obj => (isEmptyObject(obj) ? [] : obj)

export const flipObject = pipe(
  ::Object.entries,
  map(([name, value]) => [value, name]),
  fromPairs
)

export const safeToJS = value => value?.toJS?.()

export const mergeAt = (objects, aPath) => assocPath(
  aPath,
  mergeAll(
    map(path(aPath), objects)
  ),
  {}
)
