import React, { useCallback } from 'react'

import { Menu, MenuItem } from '@blueprintjs/core'
import { useDispatch } from 'react-redux'
import { UNPIN_SIMILAR_CONDITIONS } from '../../../commands/condition'
import { PAUSE_POINT_TOGGLE } from '../../../commands/edition/playback/debug'
import { factFromChoices } from '../../../engine/actions/choices'
import { connectToChoicesIdle } from '../../../engine/actions/node/insertAfterId'
import { CLEAR_TARGET } from '../../../commands/jump'
import useFactCreator from '../../../hooks/beanie/useFactCreator'
import MenuGroupCommand from '../MenuGroupCommand'
import MenuItemCommand from '../MenuItemCommand'
import SetPropertyMenuItem from '../SetPropertyMenuItem'

import { isNil } from 'ramda'
import { Sys } from 'beanie-engine-api-js'

import { GO_TO_TARGET } from 'commands/jump'
import { TOGGLE_AUTO_CHOICE, PIN_CONDITIONS_FROM_CHOICE } from 'commands/choice'
import { PIN_SIMILAR_CONDITIONS } from 'commands/condition'
import { ADD_AND_EDIT_CHOICE, ADD_AND_EDIT_CHOICE_TO_CHOICES2, ADD_AND_EDIT_CONDITION, ADD_AND_EDIT_FOLDER_ITEM, ADD_AND_EDIT_SEQUENCE, ADD_AND_EDIT_LOGIC_CONDITION,
  SET_MODE, DELETE, EDIT, BREAK_TO_ROOT, BREAK_TO_LANE, ADD_ENABLED_RULE, ADD_AND_EDIT_TT_COLUMN, ADD_TT_ROW,
  ADD_TT_DEFAULT_ROW, ADD_AND_EDIT_TASK_CONTAINER, ENABLE_DISABLE_TOGGLE, SET_BLOCK, DEBUGGING_PIN_TOGGLE } from 'commands/edition'
import { COPY_NODES, COPY_ID, PASTE_NODES } from 'commands/copyPaste'
import { PASTE_AS_CONDITIONAL } from 'commands/pasteAs'
import InsertMenuItem from './InsertMenuItem'
import SessionsSelectionMenuGroup from './SelectionOtherSessionsMenuGroup'
import SetTaskAsyncMenuItem from '../SetTaskAsyncMenuItem'
import ContextMenuExtensionPoint from '../ContextMenuExtensionPoint'
import { EXTENSION_POINTS } from 'providers/Extensions/ExtensionPoint'

/**
 * Context menu component when selection is a single node
 * We have a different component when the selection is Multiple
 */
const SingleSelectionContextMenu = ({ o, hasWriteAccess, ...others }) => {
  const dispatch = useDispatch()
  const factFromChoicesCreator = useCallback(() => dispatch(factFromChoices(o)), [o, dispatch])
  const onCreateFactFromChoices = useFactCreator(factFromChoicesCreator)

  return (
    <Menu>
      <MenuGroupCommand>
        {o.sys !== Sys.folder && hasWriteAccess && <InsertMenuItem object={o} {...others} /> }

        {o.sys === Sys.choices2 && hasWriteAccess && isNil(o.data.idle) &&
        <InsertMenuItem
          title="Insert Idle..."
          object={o}
          connectionFn={connectToChoicesIdle}
          {...others} />
        }
        {((o.sys === Sys.choices || o.sys === Sys.choices2) && hasWriteAccess) && (
          <MenuItem text="Create Fact from Choices" onClick={onCreateFactFromChoices} />
        )}

        {o.sys === Sys.marker && !!o.data.parent &&
        <MenuItem text="Break...">
          <MenuItemCommand command={BREAK_TO_ROOT} singleSelectionOnly params={[o]} /> {/* TODO: singleSelectionOnly should be removed ? */}
          <MenuItemCommand command={BREAK_TO_LANE} singleSelectionOnly params={[o]} />
        </MenuItem> }

      </MenuGroupCommand>

      <MenuGroupCommand>
        { /* Add container items: could be just one with dynamic label :S */ }
        <MenuItemCommand command={ADD_AND_EDIT_TT_COLUMN} params={[o]} />
        <MenuItemCommand command={ADD_TT_ROW} params={[o]} />
        <MenuItemCommand command={ADD_TT_DEFAULT_ROW} params={[o]} />
        <MenuItemCommand command={ADD_AND_EDIT_CONDITION} params={[o]} />
        <MenuItemCommand command={ADD_AND_EDIT_CHOICE} params={[o]} />
        <MenuItemCommand command={ADD_AND_EDIT_CHOICE_TO_CHOICES2} params={[o]} />
        <MenuItemCommand command={ADD_AND_EDIT_FOLDER_ITEM} params={[o]} />
        <MenuItemCommand command={ADD_AND_EDIT_SEQUENCE} params={[o]} />
        <MenuItemCommand command={ADD_AND_EDIT_LOGIC_CONDITION} params={[o]} />
        <MenuItemCommand command={ADD_AND_EDIT_TASK_CONTAINER} params={[o]} />
        <ContextMenuExtensionPoint point={EXTENSION_POINTS.SINGLE_SELECTION_CONTEXT_MENU} />

        {/* params here is a hack so that MenuCommandGroup works with SetPropertyMenuItem :S */}
        <SetPropertyMenuItem o={o} params={[o]} property="data.mode" command={SET_MODE} />
        <SetTaskAsyncMenuItem o={o} params={[o]} command={SET_BLOCK} />
      </MenuGroupCommand>

      <MenuGroupCommand>
        <MenuItemCommand command={ENABLE_DISABLE_TOGGLE} o={o} singleSelectionOnly params={[o]} />
        <MenuItemCommand command={ADD_ENABLED_RULE} params={[o]} />
        <MenuItemCommand command={TOGGLE_AUTO_CHOICE} params={[o]} />
      </MenuGroupCommand>

      <MenuGroupCommand>
        <MenuItemCommand command={PAUSE_POINT_TOGGLE} o={o} singleSelectionOnly params={[o]} />
        <MenuItemCommand command={DEBUGGING_PIN_TOGGLE} o={o} singleSelectionOnly params={[o]} />
        <MenuItemCommand command={PIN_CONDITIONS_FROM_CHOICE} o={o} singleSelectionOnly params={[o]} />
        <MenuItemCommand command={PIN_SIMILAR_CONDITIONS} o={o} singleSelectionOnly params={[o]} />
        <MenuItemCommand command={UNPIN_SIMILAR_CONDITIONS} o={o} singleSelectionOnly params={[o]} />
      </MenuGroupCommand>

      <MenuGroupCommand>
        <MenuItemCommand command={COPY_NODES} />
        <MenuItemCommand command={COPY_ID} />
        <MenuItemCommand command={PASTE_NODES} />
        <MenuItemCommand command={PASTE_AS_CONDITIONAL} />
      </MenuGroupCommand>
      <MenuGroupCommand>
        <MenuItemCommand command={EDIT} singleSelectionOnly params={[o]} />
        <MenuItemCommand command={DELETE} />
      </MenuGroupCommand>

      <MenuGroupCommand>
        <MenuItemCommand command={GO_TO_TARGET} singleSelectionOnly params={[o]} />
        <MenuItemCommand command={CLEAR_TARGET} singleSelectionOnly params={[o]} />
      </MenuGroupCommand>

      <SessionsSelectionMenuGroup />
    </Menu>
  )
}

export default SingleSelectionContextMenu
