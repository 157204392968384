import { all, allPass, complement, identity, inc, dec, lensProp, over, pathEq, pipe, prop, propEq } from 'ramda'
import { isContained, isContainer, model, Sys } from 'beanie-engine-api-js'

import { BOTTOM_CENTER, CENTER_LEFT, CENTER_RIGHT, TOP_CENTER } from '../../../../utils/dnd/position'
import OperationInferenceRule from '../OperationInferenceRule'
import { OneToManyOperations } from '../Operations'
import accepts from './oneToMany/accepts'

const { types: { node: { canBeRoot }, object: { Paths }, relationship: { isIdleRelationship, isLaneRootRelationship, Relationship } } } = model

//
// utilities to create the set of definitions that are in OperationsRules
//

export const buildCases = cases => cases.map(_case => new OperationInferenceRule(_case))

// target predicate
export const isOverRoot = pipe(prop('relationship'), isLaneRootRelationship)
export const isOverContained = pipe(prop('node'), isContained)
export const isOverContainer = pipe(prop('node'), isContainer)
export const isOverOtherwiseRow = pathEq(['node', 'sys'], Sys.truth_table_otherwise_row)
export const isOverRegular = allPass([
  complement(isOverRoot),
  complement(isOverContained),
])
export const isOverIdleOfChoices = allPass([
  pathEq(['node', 'sys'], Sys.choices2),
  pipe(prop('relationship'), isIdleRelationship)
])


// selection predicates
const allComply = fn => context => all(selection => fn(selection.left, context), context.selections)
export const allCanBeRoot = allComply(canBeRoot)
export const allAreRegularNodes = allComply(n => !isContained(n))
export const allValidContainerContents = allComply((dragged, { relationship: { from, path }, resolve }) =>
  accepts(resolve(from), path, dragged)
)
export const allAre = sys => allComply(propEq('sys', sys))

export const allValidContainerContentsOfTargetNode = allComply((dragged, { node }) =>
  accepts(node, Paths.container.container_content, dragged)
)

// operations
export const replaceRoot = ({ relationship }) => ({
  operation: OneToManyOperations.REPLACE,
  relationship,
  hoverPosition: CENTER_LEFT,
})

const _insertInDelta = (delta, hoverPosition) => ({ relationship }) => ({
  operation: OneToManyOperations.INSERT,
  relationship: over(lensProp('index'), delta, relationship),
  hoverPosition,
})

const calcDelta = (hoverPosition, context) => {
  const { relationship, sourceRelationship } = context

  const isDownDirection = sourceRelationship.index < relationship.index

  if (isDownDirection) {
    if (hoverPosition === TOP_CENTER) return dec
    if (hoverPosition === BOTTOM_CENTER) return identity
  } else {
    if (hoverPosition === TOP_CENTER) return identity
    if (hoverPosition === BOTTOM_CENTER) return inc
  }
}

export const insertAbove = context => _insertInDelta(calcDelta(TOP_CENTER, context), TOP_CENTER)(context)
export const insertBelow = context => _insertInDelta(calcDelta(BOTTOM_CENTER, context), BOTTOM_CENTER)(context)

const rel = (relationship, hoverPosition) => ({
  relationship,
  hoverPosition
})

export const insertAsChild = ({ node }) => rel(
  Relationship.child(node.id),
  CENTER_RIGHT,
)
export const insertInParent = ({ relationship }) => rel(
  relationship,
  CENTER_LEFT,
)

// relationship is otherwiseOf
export const insertAboveOtherwiseRow = ({ relationship: { from } }) => ({
  operation: OneToManyOperations.APPEND,
  relationship: Relationship.containerContent(from),
  hoverPosition: TOP_CENTER,
})

export const setIdle = ({ relationship }) => rel(
  relationship,
  CENTER_RIGHT,
)

export const appendContainerContent = ({ node }) => ({
  operation: OneToManyOperations.APPEND,
  // index is not really needed here,
  relationship: Relationship.containerContent(node.id),
  hoverPosition: BOTTOM_CENTER,
})
