import { message } from 'antd'
import { head, last, prop } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import { getHeadAndTailFromSelection, selectedNode, isContiguosSelectionRoughVersion } from 'selectors/nodeSelection'
import { packSelectionAsRange } from 'actions/nodes'
import copyNodes from 'model/operations/copy/copyNodes'
import copy from 'copy-to-clipboard'
import { laneClipboardContent, nodesClipboardHeader, laneClipboardHeader, nodePropertiesClipboardContent } from '../model/app/clipboard/clipboard'
import { objectsIndex } from 'selectors/apollo'
import { rootObjectIdsByLane } from 'selectors/objects'
import { revisionId as revisionIdSelector } from 'selectors/project'
import { EMPTY_ARRAY } from 'utils/object'
import { copyAsJson } from 'utils/clipboard'
import { collectChain } from '../selectors/paths'

export const SET_CLIPBOARD_META = 'SET_CLIPBOARD_META'
export const RESET_CLIPBOARD_META = 'RESET_CLIPBOARD_META'

export const resetClipboardMeta = () => ({ type: RESET_CLIPBOARD_META })
export const setClipboardMeta = clipboardMeta => ({
  type: SET_CLIPBOARD_META,
  clipboardMeta
})

export const copyNodePropertiesToClipboard = (properties, path) => async (dispatch) => {
  const quantity = Object.keys(properties).length

  const clipboard = nodePropertiesClipboardContent({
    content: {
      path,
      properties
    },
    quantity
  })

  await copyAsJson(clipboard)

  dispatch(setClipboardMeta(clipboard))

  message.success(`Copied ${quantity} properties.`)
}

const doCopyToClipboard = async (index, headId, tailId, revisionId) => {
  const { doCopyNodes } = copyNodes(index, index[headId], index[tailId], revisionId)
  const clipboard = doCopyNodes()

  await copyAsJson(clipboard)

  message.success('Copied objects to clipboard.')

  return clipboard
}

export const copyNodesToClipboard = () => async (dispatch, getState) => {
  packSelectionAsRange()(dispatch, getState)
  const state = getState()
  const index = objectsIndex(state)

  if (!isContiguosSelectionRoughVersion(state)) {
    // only copy if there is a single selection (simple or range)
    message.error('Nodes not copied, they need to be contiguous.')
    return
  }

  const revisionId = revisionIdSelector(state)
  const { headId, tailId } = getHeadAndTailFromSelection(state)
  const copiedClipboard = await doCopyToClipboard(index, headId, tailId, revisionId)

  const tmp = { ...copiedClipboard }
  tmp.manifest.sourceRevision.id = ''
  dispatch(setClipboardMeta(nodesClipboardHeader(tmp)))

}

export const copyIdToClipboard = () => (dispatch, getState) => {
  const state = getState()

  const nodeId = selectedNode(state)
  if (nodeId !== undefined) {
    copy(nodeId)
    dispatch(resetClipboardMeta())
  }
}

export const copySomethingToClipboard = (aString, feedback) => dispatch => {
  copy(aString)
  dispatch(resetClipboardMeta)
  message.success(feedback || 'Copied to clipboard !')
}

const rootToHeadAndTail = index => obj => {
  const chainIds = collectChain(index)(obj.id)
  return [index[head(chainIds)], index[last(chainIds)]]
}

export const copyLaneToClipboard = lane => async (dispatch, getState) => {
  const state = getState()
  const index = objectsIndex(state)

  const roots = (rootObjectIdsByLane(state)[lane] || EMPTY_ARRAY)
    .map(id => index[id])
    .filter(isNotNil)

  // [rootNodeId, ...] => [[headNode, tailNode], ...]
  const headAndTails = roots.map(rootToHeadAndTail(index))

  // [[headNode, tailNode], ...] => [nodesClipboard, ...]
  const chainClipboards = await Promise.all(
    headAndTails.map(([headNode, tailNode]) => {
      const { doCopyNodes } = copyNodes(index, headNode, tailNode)
      return doCopyNodes()
    })
  )

  // [nodesClipboard, ...] => LaneClipboard
  const clipboard = laneClipboardContent(
    lane,
    chainClipboards,
    roots.map(prop('id')),
    revisionIdSelector(state)
  )
  await copyAsJson(clipboard)

  dispatch(setClipboardMeta(laneClipboardHeader(clipboard)))

  message.success('Copied Lane to clipboard.')


}
