import { message } from 'antd'
import { ref, model } from 'beanie-engine-api-js'
import { pathOr } from 'ramda'

import { canPasteOnLane } from 'selectors/clipboard'
import pasteNodes from 'model/operations/paste/pasteNodes'
import { projectObject } from 'selectors/objects'
import { laneCopyName } from 'selectors/lanes'
import { objectsIndex } from 'selectors/apollo'
import { revisionId, selectedRevisionId } from 'selectors/selectors'
import { getFromJson } from 'utils/clipboard'
import { EMPTY_ARRAY } from 'utils/object'
import winston from 'utils/logger'
import { ClipboardContentType } from 'model/app/clipboard/clipboard'
import { pasteNodesConfig } from 'model/operations/paste/pasteNodeConfig'
import { acquireNodeId } from '../../model/operations/BNECopyPasteHelper'
import { appendRootToLane } from './lanes'
import checkParentshipChanges, { isUpdatable } from './checkParentshipChanges'

const { types: { node: { canBeRoot, isStrictNode } }, factory: { objects: { build, withChild, marker } } } = model


const pasteOnLane = ({ keepSourceIds }) => lane => async (dispatch, getState) => {
  const state = getState()
  const project = projectObject(state)

  if (!canPasteOnLane(state)) return

  const clipboardContent = await getFromJson()
  const config = pasteNodesConfig(getState, clipboardContent, keepSourceIds)

  switch (clipboardContent.contentType) {
    case ClipboardContentType.Lane:
      return dispatch(_pasteLane(project, clipboardContent, config))
    case ClipboardContentType.Nodes:
      return dispatch(_pasteNodesIntoLane(project, clipboardContent, lane, config))
    default: throw new Error(`Unknown clipboard content ${clipboardContent.contentType}`)
  }
}

export default pasteOnLane

const _pasteLane = (project, clipboardContent, config) => async (dispatch, getState, { synchronizer }) => {
  const state = getState()
  const index = objectsIndex(state)
  const revId = revisionId(state)
  const { lane, chainClipboards } = clipboardContent

  const unifiedClipboard = {
    content: chainClipboards.reduce((acc, { content }) => ({ ...content, ...acc }), {})
  }

  return checkParentshipChanges(null, unifiedClipboard, config, lane, async () => {
    const pastingByChain = await Promise.all(chainClipboards.map(
      clipboardContentForChain => pasteNodes(clipboardContentForChain, index, revId, config)
    ))

    await synchronizer.doSynchingBNE(
      'Paste Lane',
      api => {
        const newRootIds = []

        pastingByChain.forEach(({ copies, headId }) => {
          newRootIds.push(headId)
          Object.values(copies).forEach(node => {
            if (config.keepSourceIds && isUpdatable(api, node)) {
              api.update(node.id, node.data)
            } else {
              api.createObject(node)
            }
          })
        })

        api.update(project.id, {
          editor: {
            lanes: [
              ...pathOr(EMPTY_ARRAY, ['data', 'editor', 'lanes'], project),
              {
                name: laneCopyName(lane)(state),
                roots: newRootIds
              },
            ]
          }
        })
      }
    )
  })(dispatch, getState)
}

const _pasteNodesIntoLane = (project, clipboardContent, lane, config) => async (dispatch, getState, { synchronizer }) => {
  const { content, headId } = clipboardContent
  if (!isStrictNode(content[headId])) {
    return
  }

  const index = objectsIndex(getState())
  const revId = selectedRevisionId(getState())
  const { copies, headId: newHeadId } = pasteNodes(clipboardContent, index, revId, config)
  const newHead = copies[newHeadId]

  return checkParentshipChanges(null, clipboardContent, config, null, async () => {
    try {
      await synchronizer.doSynchingBNE(
        'Paste as Chain',
        api => {
          // hook root to lane
          appendRootToLane(lane, getOrCreateRoot(newHead, api).id)(api, dispatch, getState)
          // paste them all
          Object.values(copies).forEach(node => {
            if (config.keepSourceIds && isUpdatable(node)) {
              api.update(node.id, node.data)
            } else {
              api.createObject(node)
            }
          })
        }
      )
    } catch (e) {
      message.error('Error pasting, nothing changed.')
      winston.error(`Error pasting. Exception message is: ${e.message}`)
      winston.error('Error pasting: Most probably your clipboard included a line from an actor that does not exist where you pasted.')
      winston.error(`Error pasting: stack trace -> ${e.stack}`)
    }
  })(dispatch, getState)
}

const makeNewRoot = (head, api) => {
  const root = build(acquireNodeId(), [marker, withChild(head.id)])
  api.createObject(root)
  head.data.parent = ref(root.id)
  return root
}
const getOrCreateRoot = (head, api) => (canBeRoot(head) ? head : makeNewRoot(head, api))
