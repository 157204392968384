import { allPass, always, anyPass, cond, defaultTo, flatten, ifElse, map, mapObjIndexed, path as ramdaPath, prop, propEq, pipe, T, values, when } from 'ramda'
import { appendFlipped, isNotNilOrEmpty, isNotNil } from 'ramda-adjunct'
import cloneDeep from 'lodash.clonedeep'

import { schema, parseRef, model } from 'beanie-engine-api-js'

import { nodesClipboardContent } from 'model/app/clipboard/clipboard'

import { EMPTY_ARRAY, toArray } from 'utils/object'
import { arraySameElements } from 'utils/ramda'
import winston from 'utils/logger'

const { types: { object: { Paths } } } = model

export /* to test */ const ClipboardSpace = {
  content: 'content',
  context: 'context',
  reference: 'reference',
  provisional: 'provisional-reference'
}

const { content, context, reference, provisional } = ClipboardSpace

export /* to test */ const newClipboard = (headId, tailId, sourceRevId, sourceProjId, sourceRevName = '', sourceProjName = '') => {
  const clipboard = nodesClipboardContent({ headId, tailId, sourceRevId, sourceProjId, sourceRevName, sourceProjName })

  const addTo = where => node => {
    clipboard[where][node.id] = cloneDeep(node)
  }

  const addToContent = addTo(content)
  const addToContext = addTo(context)

  const addRef = refType => ({ to, from, path }) => {
    clipboard[refType][to.id] = [...(clipboard[refType][to.id] || []), { from: from.id, path }]
  }
  const addReference = addRef('referencesMap')
  const addProvisionalRef = addRef('provisionalRefs')

  const typeEquals = propEq('type')
  const takeNodeAnd = andDo => pipe(prop('node'), andDo)

  const addClipboardOp = pipe(
    toArray,
    map(cond([
      [typeEquals(content), takeNodeAnd(addToContent)],
      [typeEquals(context), takeNodeAnd(addToContext)],
      [typeEquals(reference), addReference],
      [typeEquals(provisional), addProvisionalRef],
      [T, () => { winston.error('Received a clipboard operation type that is unknown') }]
    ]))
  )

  const getClipboard = () => clipboard

  const existsIn = clipboardSpace => obj => !!getClipboard()[clipboardSpace][obj.id]
  const existsInContext = existsIn(context)
  const existsInContent = existsIn(content)
  const existsInClipboard = anyPass([existsInContent, existsInContext])
  const clearProvisional = () => { getClipboard().provisionalRefs = {} }

  return { addClipboardOp, getClipboard, existsInClipboard, clearProvisional }
}

//  if "HAS" -> FOLLOW and put in CONTENT
//  if "BELONGS" -> FOLLOW and put in CONTEXT
export /* for test */ const contentOrContext = (node, path) => {
  if (schema.isRefHas(node.sys, path)) {
    return content
  } else if (schema.isRefBelongs(node.sys, path)) {
    return context
  }
}

export /* for test */ const contentClipboardOp = node => ({ type: 'content', node })
export /* for test */ const contentOrContextClipboardOp = (from, to, path) => {
  const space = contentOrContext(from, path)
  return (space ? [{ type: space, node: to }] : EMPTY_ARRAY)
}
const refClipboardOp = type => (from, to, path, key) => {
  const appendIfNotNil = when(isNotNil, appendFlipped(path))
  const fullPath = defaultTo(path, appendIfNotNil(key))
  return [
    { type, from, to, path: fullPath }
  ]
}
export /* to test */ const referenceClipboardOp = refClipboardOp(reference)
// TODO: test this one
const provisionalRefClipboardOp = refClipboardOp(provisional)

/**
 *
 */
const copyNodes = (objectsIdx, headNode, tailNode, revisionId) => {

  let referenceCount = {}
  let visitedNodes = {}
  const wasRefAlreadyVisited = (from, to, path, key = '') => {
    const k = `${from}-${to}-${path}-${key}`
    referenceCount[k] = (referenceCount[k] || 0) + 1
    return referenceCount[k] > 1
  }
  const visited = node => {
    visitedNodes[node.id] = true
  }
  const isVisitedNode = node => visitedNodes[node.id]

  const fromPropNamesToClipboardOps = node => pipe(
    prop('sys'),
    // TODO: try to change it for propertyNamesOf then stop doing `data.#{dataPropName}`
    schema.propertiesOf,
    prop('data'),
    mapObjIndexed(
      (type, dataPropName) => {
        const propPath = ['data', dataPropName]
        return crawlPropIfNeeded({ node, propPath })
      }
    )
  )(node)

  // get an object, scan all its props under 'data'
  // if the type of prop is NOT ref -> DO NOT FOLLOW
  // else DO follow
  const crawl = (node, reuseContext) => {
    visited(node)

    if (!reuseContext) {
      // by default clean the referenceCount, but don't do it when we are recursing
      referenceCount = {}
      visitedNodes = {}
    }

    return flatten(values(fromPropNamesToClipboardOps(node)))
  }


  // key is optional, represents the index of an array or the key of a map its used to extend the path when applicable
  const addOpsAndMaybeCrawl = (node, propPath) => (idValue, leftValue) => {
    const key = schema.isArray(node.sys, propPath) ? Number(leftValue) : leftValue
    const to = objectsIdx[parseRef(idValue)]
    if (!to) return []

    if (wasRefAlreadyVisited(node.id, to.id, propPath, key)) return []

    const isCompositionalRef = schema.isRefCompositional(node.sys, propPath)
    if (!isCompositionalRef) return provisionalRefClipboardOp(node, to, propPath, key)

    return [
      ...referenceClipboardOp(node, to, propPath, key),
      ...(!isVisitedNode(to) ?
        [
          ...contentOrContextClipboardOp(node, to, propPath),
          ...crawl(to, true)
        ]
        : EMPTY_ARRAY
      )
    ]
  }

  // adapts to follow arrays or maps of refs
  const crawlProp = ({ node, propPath }) => {
    const addOpsAndFollow = addOpsAndMaybeCrawl(node, propPath)
    const isRef = schema.isRef(node.sys, propPath)
    const startFollowing = isRef ?
      addOpsAndFollow
      : pipe( // its rather array or map of refs
        mapObjIndexed(addOpsAndFollow),
        values,
        flatten
      )

    const refValue = ramdaPath(propPath, node)
    return startFollowing(refValue)
  }

  const crawlPropIfNeeded = ifElse(
    needToCrawlProp(headNode, tailNode),
    crawlProp,
    // return [] so it can then be flattened and reduced (dissapear from result ops)
    always(EMPTY_ARRAY)
  )

  //
  // MAIN copyNodes
  const doCopyNodes = () => {
    const clipboard = newClipboard(headNode.id, tailNode.id, revisionId)
    const operations = ([
      contentClipboardOp(headNode),
      ...crawl(headNode)
    ])
    operations.forEach(clipboard.addClipboardOp)

    // Promote provisional references where FROM and TO are in context or content to referencesMap
    operations
      .filter(allPass([
        propEq('type', provisional),
        pipe(prop('to'), clipboard.existsInClipboard),
        pipe(prop('from'), clipboard.existsInClipboard)
      ]))
      .map(({ type, ...rest }) => ({ type: reference, ...rest }))
      .forEach(clipboard.addClipboardOp)

    // dinamite provisionalRefs
    clipboard.clearProvisional()

    return clipboard.getClipboard()
  }

  return {
    // public API
    doCopyNodes,

    // private API - exported for testing
    crawl,
  }

}

const needToCrawlProp = (headNode, tailNode) => ({ node, propPath }) => {
  const pathsMatch = (expectedPath, expectedNode) =>
    arraySameElements(propPath, expectedPath) && node.id === expectedNode.id

  const isSomeRef = schema.isSomeRef(node.sys, propPath)
  const refValue = ramdaPath(propPath, node)
  const isTailEdge = pathsMatch(Paths.node.child, tailNode)
  const isHeadEdge = pathsMatch(Paths.node.parent, headNode)

  return isSomeRef && isNotNilOrEmpty(refValue) && !(isHeadEdge || isTailEdge)
}

export default copyNodes
