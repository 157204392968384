import { model, Sys } from 'beanie-engine-api-js'
import { selectionToLeftAndRight } from '../../../../selectors/nodeSelection'
import { moveArrayIndexTo } from '../../../../utils/object'
import { BOTTOM_CENTER, TOP_CENTER } from '../../../../utils/dnd/position'
import { OneToManyOperations } from '../Operations'

const { types: { node: { canBeRoot, sysCanBeRoot } } } = model

/**
 * Connector for the lane's roots relationship.
 * This needs special treatment since roots are referenced from lanes
 * which are embedded objects in the project object
 */
const RootsConnector = {

  connect: ({ api }, selection, relationship, operation) => {
    const { left, right } = selectionToLeftAndRight(selection)
    const { lane, index } = relationship

    // disconnect left & right rewiring edges if any
    const leftObject = api.obj.get(left)
    const rightObject = api.obj.get(right)
    const toLeft = leftObject.get_parent()
    if (toLeft) {
      const c = rightObject.get_child_node()
      if (c) {
        c.set_parent(toLeft)
      }
    }
    leftObject.set_parent(null)

    // insert into roots
    updateRoots(api, lane, roots =>
      applyOperation(api, roots, operation, index, leftObject, rightObject)
    )
  },

  /**
   * Only allow to connect as roots either markers or folders
   */
  canConnect: ({ resolve }, selection) => {
    const { left } = selectionToLeftAndRight(selection)
    return canBeRoot(resolve(left))
  },

  disconnect: ({ api }, selection, relationship, hoverPosition) => {
    const { left, right } = selectionToLeftAndRight(selection)

    const isSingleNode = left === right

    // delete from the lane ref
    const { lane, index } = relationship

    updateRoots(api, lane, roots => {
      // rewire any children as root marker now
      const child = api.obj.get(right).get_child_node()
      const isRootsSorting = [TOP_CENTER, BOTTOM_CENTER].includes(hoverPosition)
      if (child && !(isSingleNode && isRootsSorting)) { // if it is a single node root selection, move entire tree chain
        if (sysCanBeRoot(child.get_sys())) {
          roots[index] = child.get_id()
          child.set_parent(null)
        } else {
          const newRoot = api.obj.new(Sys.marker)
          roots[index] = newRoot.get_id()
          child.set_parent(newRoot)
        }
      } else {
        roots.splice(index, 1)
      }
      return roots
    })
  },

  move: ({ api }, relationship, fromIndex, toIndex) => {
    const { lane } = relationship

    updateRoots(api, lane, roots =>
      moveArrayIndexTo(fromIndex, toIndex, roots)
    )
  }

}

const updateRoots = (api, lane, fn) => {
  // TODO: in theory we could avoid overriding the whole "lanes" array and using the new Proxies
  //   only modify directly the roots fields. But the engine (BNEStore) is not prepared
  //   to handle such a ChangeSet. @see https://app.clickup.com/t/de3jv5. Undoing such a changeSet will fail
  //   and applying it to other engines also will fail (collab)
  const { editor } = api.project.get_node().get_data()
  editor.lanes = editor.lanes.asArray().toJSONObject().map(aLane => {
    if (aLane.name === lane) {
      aLane.roots = fn(aLane.roots)
    }
    return aLane
  })
}

/**
 *
 */
const applyOperation = (api, array, operation, index, left, right) => {
  switch (operation) {

    case OneToManyOperations.REPLACE: {
      const toBeReplacedId = array[index]
      api.obj.get(toBeReplacedId).set_parent(right)
      array[index] = left.get_id()
      return array
    }

    case OneToManyOperations.INSERT: {
      // TODO: if the "left" node is not a marker/folder then should we create a synthetic marker ?
      array.splice(index, 0, left.get_id())
      return array
    }
    default: throw new Error(`Unknown operation ${operation}`)
  }
}

export default RootsConnector
