import React from 'react'
import { Provider } from 'react-redux'
import { pipe, sortBy, map, prop } from 'ramda'

import { ADD_ROOT_MARKER, ADD_ROOT_FOLDER, ADD_LANE, DELETE_LANE } from 'commands/edition'
import { COPY_LANE, PASTE_ON_LANE } from 'commands/copyPaste'
import { COLLAPSE_ALL_LANE_ROOTS } from 'commands/lane'
import { COLLAPSE_OTHER_LANES, EXPAND_ALL_LANE_ROOTS, TOGGLE_COLLAPSE_LANE } from '../../../commands/lane'

import secure from 'hocs/secure'
import { isCurrentRevisionWritable } from 'security/project'
import RevisionExtensions from 'providers/RevisionExtensions'
import ContextMenuExtensionPoint from '../ContextMenuExtensionPoint'
import { EXTENSION_POINTS } from 'providers/Extensions/ExtensionPoint'

import MenuCommandGroup from '../MenuGroupCommand'
import MenuItemCommand from '../MenuItemCommand'

import { Menu, MenuItem } from '@blueprintjs/core'

const LaneContextMenu = ({ lane, store, hasWriteAccess }) => (
  <Provider store={store} >
    <RevisionExtensions>
      <Menu>
        <MenuCommandGroup>
          {
          hasWriteAccess &&
          <MenuItem text="Insert Root...">
            {
              pipe(
                sortBy(prop('label')),
                map(command => (
                  <MenuItemCommand key={command.label} command={command} params={[lane]} />
                ))
              )([
                ADD_ROOT_MARKER,
                ADD_ROOT_FOLDER,
              ])
            }
          </MenuItem>
        }
          <ContextMenuExtensionPoint point={EXTENSION_POINTS.LANE_CONTEXT_MENU} />
        </MenuCommandGroup>
        <MenuCommandGroup>
          <MenuItemCommand key={ADD_LANE.label} command={ADD_LANE} />
        </MenuCommandGroup>
        <MenuCommandGroup>
          <MenuItemCommand key={COPY_LANE.label} command={COPY_LANE} params={[lane]} />
          <MenuItemCommand key={PASTE_ON_LANE.label} command={PASTE_ON_LANE} params={[lane]} />
        </MenuCommandGroup>
        <MenuCommandGroup>
          <MenuItem text="Expand/Collapse">
            <MenuCommandGroup>
              <MenuItemCommand key="TOGGLE_COLLAPSE_LANE" command={TOGGLE_COLLAPSE_LANE} params={[lane]} />
              <MenuItemCommand key={COLLAPSE_OTHER_LANES.label} command={COLLAPSE_OTHER_LANES} params={[lane]} />
            </MenuCommandGroup>
            <MenuCommandGroup>
              <MenuItemCommand key={COLLAPSE_ALL_LANE_ROOTS.label} command={COLLAPSE_ALL_LANE_ROOTS} params={[lane]} />
              <MenuItemCommand key={EXPAND_ALL_LANE_ROOTS.label} command={EXPAND_ALL_LANE_ROOTS} params={[lane]} />
            </MenuCommandGroup>
          </MenuItem>
        </MenuCommandGroup>
        <MenuCommandGroup>
          <MenuItemCommand key={DELETE_LANE.label} command={DELETE_LANE} params={[lane]} />
        </MenuCommandGroup>
      </Menu>
    </RevisionExtensions>
  </Provider>
)

export default secure('hasWriteAccess', isCurrentRevisionWritable)(LaneContextMenu)
