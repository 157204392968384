import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Timeline as ReactTimeline } from '@xzdarcy/react-timeline-editor'

import { ViewPanelVisibleContentContext } from 'components/ViewPanel/ViewPanelContexts'

import { useSize } from 'hooks/ui/useSize'
import useObject from 'hooks/beanie/useObject'
import { modelToUi } from './timelineData'
import { safeId } from 'model/utils'
import { objectsIndex } from 'selectors/apollo'
import { StepKeyframe } from './Action/StepKeyframe'

import styles from './TimelinePanel.scss'
import TimelineList from './TimelineList'
import { NEGATIVE_BUFFER_SECONDS, getMinTime } from './timehelp'
import { max, min } from 'ramda'

export const scaleWidth = 100

const CustomScale = ({ scale }) => (scale - NEGATIVE_BUFFER_SECONDS)

const Timeline = ({ timelineId, scale = 5, changeScale }) => {
  const sToPx = s => s * (scaleWidth / scale)
  const viewPanelVisibleContentRef = useContext(ViewPanelVisibleContentContext)
  const listHeight = useSize(viewPanelVisibleContentRef)
  const height = useMemo(() => listHeight || '100%', [listHeight])

  const timeline = useObject(safeId(timelineId))
  const objsIdx = useSelector(objectsIndex)
  const timelineData = useMemo(() => modelToUi(objsIdx)(timeline), [timelineId])
  const [data, setData] = useState(timelineData)
  const expandedData = useMemo(() => data.filter(row => !row.collapsed), [data])
  const timelineState = useRef()
  const domRef = useRef()

  const [cursorTime, setCursorTime] = useState(0)
  const setCursor = useCallback(time => setCursorTime(time), [setCursorTime])

  useEffect(() => {
    const minTime = min(getMinTime(timelineData), NEGATIVE_BUFFER_SECONDS)
    const initScroll = max(0, sToPx(minTime))

    timelineState.current.setScrollLeft(initScroll)
    timelineState.current.setTime(NEGATIVE_BUFFER_SECONDS)
  }, [])

  return ([
    <TimelineList data={data} setData={setData} height={height} domRef={domRef} timelineState={timelineState} cursorTime={cursorTime} changeScale={changeScale} scale={scale} />,
    <ReactTimeline
      className={styles.timeline}
      style={{ width: '100%', height }}
      scale={scale}
      scaleWidth={scaleWidth}
      onChange={(_data) => {
          setData(_data)
      }}
      editorData={expandedData}
      getScaleRender={_scale => <CustomScale scale={_scale} />}
      ref={timelineState}

      disableDrag
      getActionRender={(action, row) => {
          if (['controlkey', 'event'].includes(action.data.actionType)) {
              return <StepKeyframe action={action} row={row} />
          }
      }}

      onScroll={({ scrollTop }) => {
        domRef.current.scrollTop = scrollTop
      }}
      onClickTimeArea={setCursor}
      onCursorDrag={setCursor}
    />
  ])
}

export default Timeline